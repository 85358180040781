import * as React from "react";
import Section from "../components/section";
import Heading from "../components/heading";
import MultiItemCarousel from "../components/multiItem-crousel"
import { multiData } from '../components/materials-data';

export default function Materials({ links }) {

  const heading = "Materiais de Apoio"
  const secondaryHeading = "Conheça os materiais que produzi para lhe auxiliar na prática da Educação Domiciliar"

  return (
    <Section>
      <Heading center className="cl-identity">{heading}</Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <MultiItemCarousel multiData={multiData} />
    </Section>
  );
}
