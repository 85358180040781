import * as React from "react";
import * as styles from "./features.module.css";
import Section from "../components/section";
import Heading from "../components/heading";
import * as image01 from "../images/tira-duvidas.jpg";
import * as image02 from "../images/planejamento-curricular.jpg";
import { Button } from "react-bootstrap";
import Fade from "react-reveal/Fade";
import { Link } from "gatsby"
import Testimonials from "../components/Testimonials"

export default function Features({ links }) {

  const heading = "Serviços de Consultoria"
  const secondaryHeading = "Escolha a opção que melhor atende a sua necessidade"

  const content = [
    {
      primaryText: "Tira-Dúvidas",
      secondaryText: "Um bate-papo mais aberto para você tirar todas as suas dúvidas a respeito de como começar a praticar a Educação Domiciliar.",
      image: image01,
      route: "/tira-duvidas"
    },
    {
      primaryText: "Planejamento Curricular",
      secondaryText: "Um planejamento personalizado para o seu filho, com direito a dicas de materiais e um currículo anual.",
      image: image02,
      route: "/planejamento-curricular"
    }
  ]

  return (
    <Section>
      <Heading center className="cl-identity">{heading}</Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <div className={styles.content}>
        {content.map((item, i) => (
          <Feature
              {...item}
              orientation={i % 2 === 0 ? "default" : "reverse"}
          />
        ))}
      </div>
      <Testimonials />
    </Section>
  );
}

function Feature({
  primaryText,
  secondaryText,
  image,
  route,
  orientation = "default",
}) {
  const orientationStyle = orientation === "default" ? "" : styles.reverse;
  const classImage =  orientation === "default" ? styles.imageLeft : styles.imageRight;

  const props = {
    duration: 2000,
    left: orientation === "default",
    right: orientation === "reverse",
  }

  return (
    <Fade {...props} duration={2000}>
      <div className={`${styles.featureContainer} ${orientationStyle}`}>
        <div className={styles.copyColumn}>
          <div className={styles.copyContainer}>
            <h4 className={styles.primaryText}>
              {primaryText}
            </h4>
            <p className={styles.secondaryText}>
              {secondaryText}
            </p>
            <div className={styles.buttonContainer}>
              <Button as={Link} to={route} className={styles.learnMoreButton}>Saiba mais...</Button>
            </div>
          </div>
        </div>
        <div className={styles.imageColumn}>
          <img className={classImage} src={image} alt={primaryText} />
        </div>
      </div>
    </Fade>
  );
}
