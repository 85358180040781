import * as React from "react";
import Section from "../components/section";
import Heading from "../components/heading";
import SocialButtons from "../components/SocialButtons"

export default function CallToAction() {
  const heading = "Redes Sociais"
  const secondaryHeading = "Me siga nas redes sociais para ficar por dentro das novidades!"
  return (
    <Section>
      <Heading center className="cl-identity">{heading}</Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <SocialButtons home />
    </Section>
  );
}

