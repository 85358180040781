import React from "react"
import { Link } from "gatsby"
import { FaInstagram, FaYoutube, FaFacebook } from "react-icons/fa"
import { SiGmail } from "react-icons/si"
import { Row } from "react-bootstrap"
import Fade from "react-reveal/Fade"

import { blogConfig } from "../../blogConfig"

const Instagram = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlInstagram}
    className="text-decoration-none"
  >
    <div className="square-100 bg-instagram m-2 bg-warning rounded-circle d-flex justify-content-center align-items-center">
      <FaInstagram size={48} color="var(--color-fundo)" />
    </div>
  </a>
)

const Youtube = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlYoutube}
    className="text-decoration-none"
  >
    <div className="square-100 bg-youtube m-2 rounded-circle d-flex justify-content-center align-items-center">
      <FaYoutube size={48} color="var(--color-fundo)" />
    </div>
  </a>
)

const Facebook = () => (
  <a
    target="_blank"
    rel="noopener noreferrer"
    href={blogConfig.urlFacebook}
    className="text-decoration-none"
  >
    <div className="square-100 bg-facebook m-2 rounded-circle d-flex justify-content-center align-items-center">
      <FaFacebook size={48} color="var(--color-fundo)" />
    </div>
  </a>
)

const Gmail = () => (
  <Link to="/contato" className="text-decoration-none">
    <div className="square-100 bg-gmail m-2 rounded-circle d-flex justify-content-center align-items-center">
      <SiGmail size={48} color="var(--color-fundo)" />
    </div>
  </Link>
)

const SocialButtons = ({ home = false }) => {
  const position = home
    ? "mb-5 justify-content-center"
    : "mb-5 mr-5 justify-content-end"

  return (
    <Row className={position}>
      <Fade bottom duration={1500}>
        <Instagram />
      </Fade>
      <Fade bottom duration={2000}>
        <Youtube />
      </Fade>
      <Fade bottom duration={2500}>
        <Facebook />
      </Fade>
      <Fade bottom duration={3000}>
        {!home && <Gmail />}
      </Fade>
    </Row>
  )
}

export default SocialButtons
