import React from "react";
import * as styles from "./copy.module.css";
import Section from "../components/section";
import Heading from "../components/heading";
import Container from "../components/container";
import * as image from "../images/sbore-mim.jpg"

export default function Copy() {

  const heading = "Sobre mim"
  const secondaryHeading = "Conheça um pouco da minha história"

  return (
    <Section>
      <Heading center className="cl-identity">{heading} </Heading>
      <Heading secondary center>
        {secondaryHeading}
      </Heading>
      <Container className={styles.container}>
        <CopyContent />
      </Container>
    </Section>
  );
}

function CopyContent() {
  return (
    <Container className={styles.copyContainer}>
      <div className={styles.imageContainer}>
        <img className={styles.image} src={image} alt="Sobre mim" />
      </div>
      <p className={styles.text}>
        Meu nome é Jéssica, sou casada há 9 anos, mãe de três crianças lindas: Calebe de 8 anos, Ana de 5 anos e Tito de 1 ano.
        <br/><br/>
        No ano de 2011 iniciei a graduação em Direito e foi neste período que conheci meu esposo, Leonardo, que cursava Tecnologia da Informação. Depois de 2 anos e meio estudando Direito, entendi que minha missão era formar minha família, então, deixei a graduação de lado e me dediquei a construir bases sólidas no meu lar para receber meus filhos.
        <br/><br/>
        Não escolhi o homeschooling, ele me escolheu. Depois de enfrentar uma série de problemas na escola que meu filho frequentava, comecei a estudar sobre a educação domiciliar e entendi que este modelo era a melhor opção para nós.
        <br/><br/>
        Pratico o homeschooling há 3 anos e já colho alguns frutos deste trabalho incrível. Tive o privilégio de alfabetizar o Calebe e hoje me sinto orgulhosa ao vê-lo ler com desenvoltura. Ana, com seus 5 aninhos, já consegue ler também. Participar deste processo é muito recompensador!
        <br/><br/>
        Atualmente eu estudo Letras, estou no meu último ano da graduação, além disso, me dedico a informar famílias através do meu canal do Youtube e Instagram, onde compartilho informações de grande utilidade para famílias que desejam iniciar na modalidade.
        <br/><br/>
        Por conta da grande quantidade de dúvidas e da alta demanda por informações personalizadas, resolvi criar dois planos de consultoria para ajudar famílias que desejam praticar o homeschooling. O plano “Tira-Dúvidas” destinado a quem quer iniciar a educação domiciliar, mas ainda possuem muitas dúvidas a respeito. E o plano “Planejamento Curricular” para famílias que já decidiram começar, mas precisam de uma espinha dorsal para seguirem; neste último plano de consultoria nos reuniremos para montar um plano de ensino anual, a fim de guiar a família pelo homeschooling.
        <br/><br/>
        Além disso, ofereço alguns materiais de apoio que podem auxiliar na caminhada do homeschooling. Não deixe de explorar os materiais, tem gratuitos e pagos.
        <br/><br/>
        Será um prazer te ajudar! Me coloco à disposição.
      </p>

    </Container>
  );
}
