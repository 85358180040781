import * as React from "react";
import * as styles from "./hero.module.css";
import Img from "gatsby-image"
import Section from "../components/section";
import Heading from "../components/heading";
import {graphql, useStaticQuery} from "gatsby";
import Slide from "react-reveal/Slide";
import Fade from "react-reveal/Fade";

export default function Hero() {
  const Image = () => {
    const data = useStaticQuery(graphql`
    query {
      placeholderImage: file(relativePath: { eq: "hero.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 1200, maxHeight: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

    if (!data?.placeholderImage?.childImageSharp?.fluid) {
      return <div>Picture not found</div>
    }

    return (
      <Img
        className={styles.imageBorderRadius}
        fluid={data.placeholderImage.childImageSharp.fluid}
        fadeIn
        durationFadeIn={1000}
      />
    )
  }

  const heading = "A Mãe Homeschooler"
  const secondaryHeading = "@amaehomeschooler"
  const primaryText = "Olá! Eu sou mãe educadora de três crianças, graduanda em Letras, produtora de conteúdo para famílias Homeschoolers e criei este site para oferecer ferramentas a você que pratica ou deseja praticar o Ensino Domiciliar. Os anos de prática e estudos nesta área me trouxeram a experiência e conhecimentos necessários para apoiar outras famílias. Conheça os meus serviços/produtos e escolha a melhor forma em que eu poderei lhe ajudar. Estou à disposição!"

  return (
    <Section>
      <div className={styles.root}>
        <div className={styles.content}>
          <Slide top duration={4000}>
            <Heading as="h6" className={styles.secondaryHeading}>
              {secondaryHeading}
            </Heading>
          </Slide>
          <Slide bottom duration={2000}>
            <Heading as="h1" className={styles.heading}>
              {heading}
            </Heading>
          </Slide>
          <Fade duration={5000}>
            <p>{primaryText}</p>
          </Fade>
        </div>
        <div className={styles.image}>
          <Fade duration={3000}>
            <Image />
          </Fade>
        </div>
      </div>
    </Section>
  );
}
